<template>
  <div id="settings-profile">
     <!-- Back Button -->
    <v-btn id="back-button" icon @click="$router.push('/settings')" class="ml-minus-2">
      <v-icon>
        mdi-arrow-left
      </v-icon>
    </v-btn>

    <!-- Title -->
    <h1 class="my-5 font-weight-medium">{{ $t('settings.profile.title') }}</h1>

    <!-- Profile Card -->
    <ProfileCard />

    <!-- List One -->
    <ListCard
      :data="listOne"
      class="mt-8"
    />

    <!-- List Two -->
    <ListCard
      :data="listTwo"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import countries from 'i18n-iso-countries';
import i18n from '@/plugins/i18n';

export default {
  name: 'SettingsProfile',
  components: {
    ProfileCard: lazyLoad('components/Shared/Cards/ProfileCard'),
    ListCard: lazyLoad('components/Settings/Card'),
  },
  computed: {
    ...mapGetters('user', [
      'user',
    ]),
    listOne() {
      return {
        footer: 'settings.profile.listFooter',
        items: [{
          svg: 'Profile',
          title: 'settings.profile.list.name',
          path: '',
          disabled: true,
          data: `${this.user.personalData.firstName} ${this.user.personalData.lastName}`,
        },
        {
          svg: 'Call',
          title: 'settings.profile.list.phone',
          path: '',
          disabled: true,
          data: this.user.contactData.mobile,
        },
        {
          svg: 'Home',
          title: 'settings.profile.list.address',
          path: '',
          disabled: true,
          data: `${this.user.contactData.street} ${this.user.contactData.houseNo}, ${this.user.contactData.city}, ${countries.getName(this.user.contactData.countryResidence, i18n.locale.split('-')[0])}`,
        },
        {
          svg: 'Heart',
          title: 'settings.profile.list.birth',
          path: '',
          disabled: true,
          data: this.user.identificationData.birthDay,
        },
        {
          svg: 'Location',
          title: 'settings.profile.list.place',
          path: '',
          disabled: true,
          data: this.user.identificationData.birthPlace,
        },
        {
          svg: 'Location',
          title: 'settings.profile.list.citizenship',
          path: '',
          disabled: true,
          data: countries.getName(this.user.identificationData.countryPrimCitizen, i18n.locale.split('-')[0]),
        },
        {
          svg: 'Bag',
          title: 'settings.profile.list.tax',
          path: '',
          disabled: true,
          data: this.user.financialData.taxNo,
        }],
      };
    },
    listTwo() {
      return {
        title: 'settings.profile.secondListTitle',
        items: [{
          svg: 'Download',
          title: 'settings.profile.list.request',
          path: '',
          disabled: true,
          link: true,
          externalPath: 'https://www.cometum.com/de/my-personal-data',
        }],
      };
    },
  },
};
</script>
